import React from 'react'

import Circles from '@assets/circles.inline.svg'
import styles from './Hero.module.scss'

const Hero = () => (
  <header className={styles.hero}>
    <h1 className="t-heading-m">
      Maximizing the socio-ecological impact of responsible businesses in
      emerging markets
    </h1>

    <p>
      MOSS is a consultancy and financier that makes cutting-edge scientific
      knowledge accessible through practical tools, workshops, and mentorship
      programs that help tackle the root causes of societal problems.
    </p>

    <Circles className={styles.graphic} />
  </header>
)

export default Hero
