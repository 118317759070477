import React from 'react'
import PropTypes from 'prop-types'

import styles from './Columns.module.scss'

const Columns = ({ children, classNameExt, stylesExt }) => (
  <div
    className={[styles.wrapper, ...[classNameExt]].join(' ')}
    styles={stylesExt}
  >
    <div className={styles.columns}>
      {React.Children.map(children, (child, index) => (
        <div key={`_${index}`} className={styles.column}>
          {child}
        </div>
      ))}
    </div>
  </div>
)

Columns.propTypes = {
  children: PropTypes.node.isRequired,
  classNameExt: PropTypes.string,
  stylesExt: PropTypes.object,
}

export default Columns
