import React from 'react'

import Hero from '@components/pages/home/Hero'
import Partners from '@components/pages/home/Partners'
import Pitch from '@components/pages/home/Pitch'
import Quote from '@components/pages/home/Quote'
import Root from '@layouts/Root'
import Values from '@components/pages/home/Values'

const IndexPage = () => (
  <Root>
    <Hero />
    <Pitch />
    <Values />
    <Partners />
    <div className="wrapper-l">
      <hr />
    </div>
    <Quote />
  </Root>
)

export default IndexPage
