import React from 'react'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

import styles from './Pitch.module.scss'

const Pitch = () => {
  const data = useStaticQuery(graphql`
    query HomePitchQuery {
      image1: file(relativePath: { eq: "images/homePitch1.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(relativePath: { eq: "images/homePitch2.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section className="wrapper-l section">
      <div className={styles.part}>
        <div className={styles.image}>
          <Img fluid={data.image1.childImageSharp.fluid} />
        </div>

        <div className={styles.copy}>
          <h2 className="t-heading-xs">
            Helping businesses tackle societal issues
          </h2>
          <p>
            Every year hundreds of thousands of organizations pursue responsible
            business objectives to tackle important and urgent grand challenges
            such as poverty, inequality, and environmental degradation. That’s
            awesome.
          </p>
          <p>
            Unfortunately, the same strategic due diligence that they would
            exercise for, say, new market entrance, is not employed in
            determining where and how to tackle these societal issues. In doing
            so they tend to address the symptoms of problems, which means that
            at best they waste valuable resources without creating meaningful
            change and at worst they validate the problem by creating vested
            stakeholder interests in its continued existence.
          </p>
          <p>
            Everyone has good intentions. No doubt. However, when it comes to
            combining the direct and tough business world with the complex and
            delicate socio-ecological world through responsible business, even
            the best intentions can have worse outcomes.
          </p>
        </div>
      </div>

      <div className={styles.part}>
        <div className={styles.copy}>
          <h2 className="t-heading-xs">
            Cultivating resilience through systems thinking
          </h2>
          <p>
            Responsible businesses and their stakeholders need a mindset change.
          </p>
          <p>
            Viewing ingrained socio-ecological problems from a systemic
            perspective allows businesses to engage them more effectively. That
            requires understanding the interdependencies and dynamics between
            all the factors that lead to unwanted system behavior and
            identifying the most effective leverage points to realize change.
          </p>
          <p>
            Businesses that do so design various kinds of interventions at
            different levels so that as many people as possible benefit
            socially, environmentally, and economically.
          </p>
          <p>
            That’s easier said than done, but we can help you with that. It’s
            what we do.
          </p>
        </div>
        <div className={styles.image}>
          <Img fluid={data.image2.childImageSharp.fluid} />
        </div>
      </div>
    </section>
  )
}

export default Pitch
