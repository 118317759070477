import React from 'react'

import imgSteve from '@assets/images/homeQuote1.png'
import styles from './Quote.module.scss'

const Quote = () => (
  <section className="section wrapper-s">
    <div className={styles.quote}>
      <div className={styles.text}>
        As we enter the Decade of Action for the Sustainable Development Goals
        (SDGs), there is an urgent need for innovative responsible business
        solutions that effectively transform systems for more sustainable
        societal outcomes.
      </div>

      <div className={styles.author}>
        <div className={styles.photo}>
          <img src={imgSteve} alt="Dr. Steve Kennedy" />
        </div>

        <div className={styles.person}>
          <span className={styles.person__name}>Dr. Steve Kennedy</span>
          <br />
          <span className={styles.person__function}>
            Associate Professor – Rotterdam School of Management
          </span>
        </div>
      </div>
    </div>
  </section>
)

export default Quote
