import React from 'react'

import Columns from '@components/ui/Columns'
import Intro from '@components/ui/Intro'
import Partner from './Partner'
import data from './partners.json'
import styles from './Partners.module.scss'

const Partners = () => (
  <section className="section">
    <Intro sectionTitle="Who we've worked with" wrapper="wrapper-s">
      <p>
        Although our core clientele are responsible businesses such as social
        enterprises (SEs), we leverage multiple intervention points within the
        systems we are active. This means that we work with a variety of
        different organizations across sectors such as universities, government
        institutions, and NGOs.
      </p>
    </Intro>

    <div className=" wrapper-l">
      <div className={styles.partnerCatWrapper}>
        <h3 className="t-heading-xs t-center">Universities</h3>

        <div className={styles.partnersWrapper}>
          {data.universities
            .filter((entry) => entry.logo && entry.logo !== '')
            .map((entry) => (
              <Partner key={entry.name} {...entry} />
            ))}
        </div>
      </div>

      <div className={styles.partnerCatWrapper}>
        <h3 className="t-heading-xs t-center">Accelerators &amp; Incubators</h3>

        <div className={styles.partnersWrapper}>
          {data.investors
            .filter((entry) => entry.logo && entry.logo !== '')
            .map((entry) => (
              <Partner key={entry.name} {...entry} />
            ))}
        </div>
      </div>

      <div className={styles.partnerCatWrapper}>
        <h3 className="t-heading-xs t-center">Social Enterprises</h3>

        <div className={styles.partnersWrapper}>
          {data.socialenterprises
            .filter((entry) => entry.logo && entry.logo !== '')
            .map((entry) => (
              <Partner key={entry.name} {...entry} />
            ))}
        </div>
      </div>
    </div>
  </section>
)

export default Partners
