import React from 'react'
import PropTypes from 'prop-types'

import styles from './Partner.module.scss'

const Partner = ({ name, description, website, logo }) => (
  <div className={styles.partner}>
    <div className={styles.logobox}>
      <img
        className={styles.logo}
        src={require(`@assets/partners/${logo}`)}
        alt={`${name} logo`}
      />
    </div>

    <div className={styles.info}>
      <h4 className={styles.name}>{name}</h4>

      <p className={styles.description}>{description}</p>

      {website && (
        <a
          href={website}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.website}
        >
          Visit Website
        </a>
      )}
    </div>
  </div>
)

Partner.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  website: PropTypes.string,
  logo: PropTypes.string.isRequired,
}

export default Partner
