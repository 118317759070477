import React from 'react'

import Columns from '@components/ui/Columns'
import Intro from '@components/ui/Intro'
import styles from './Values.module.scss'

const Values = () => (
  <section className="section wrapper-l">
    <div className={styles.values}>
      <Intro sectionTitle="Vision &amp; Values">
        <p>Our vision is to eliminate the root causes of societal problems.</p>
      </Intro>

      <Columns>
        <div className={styles.dos}>
          <h3 className="t-heading-s">What we do</h3>

          <ul>
            <li>Obsess over systems</li>
            <li>Respect cultures</li>
            <li>Embrace cutting-edge science</li>
            <li>High-five </li>
            <li>Build resilience</li>
            <li>Blow minds</li>
            <li>Facilitate change</li>
            <li>Social innovation</li>
            <li>Exceed expectations</li>
            <li>Party. Hot Rod styles</li>
          </ul>
        </div>

        <div className={styles.donts}>
          <h3 className="t-heading-s">What we don't</h3>

          <ul>
            <li>Sacrifice quality for profit</li>
            <li>Ignore grand challenges</li>
            <li>Lose at Catan</li>
            <li>Harm, hog, or hide</li>
            <li>Compromise our values</li>
            <li>Take life too seriously</li>
            <li>Resist cake</li>
            <li>Short-terminism</li>
            <li>Work alone</li>
            <li>Flat Earth theory</li>
          </ul>
        </div>
      </Columns>
    </div>
  </section>
)

export default Values
